/* Base font sizes for Bootstrap utility classes */
:root {
  --fs-1: 32px; /* Large headings */
  --fs-2: 26px; /* Medium headings */
  --fs-3: 22px; /* Small headings */
  --fs-4: 19px; /* Subheadings/large text */
  --fs-5: 16px; /* Standard text */
  --fs-6: 8px; /* Small text */
  --fs-7: 6px; /* Small text */
}

/* Mobile screens */
@media (max-width: 640px) {
  :root {
    --fs-1: 26px; /* Scaled down large headings */
    --fs-2: 21px; /* Scaled down medium headings */
    --fs-3: 18px; /* Scaled down small headings */
    --fs-4: 15px; /* Scaled down subheadings/large text */
    --fs-5: 13px; /* Scaled down standard text */
    --fs-6: 8px; /* Scaled down small text */
    --fs-7: 6px; /* Scaled down small text */
  }
}

/* Tablets */
@media (min-width: 640px) and (max-width: 1024px) {
  :root {
    --fs-1: 35px;
    --fs-2: 29px;
    --fs-3: 25px;
    --fs-4: 22px;
    --fs-5: 18px;
    --fs-6: 10px;
    --fs-7: 8px;
  }
}

/* Medium-large screens */
@media (min-width: 1024px) and (max-width: 1599px) {
  :root {
    --fs-1: 45px;
    --fs-2: 38px;
    --fs-3: 33px;
    --fs-4: 29px;
    --fs-5: 24px;
    --fs-6: 12px;
    --fs-7: 8px;
  }
}

/* Desktops */
@media (min-width: 1600px) {
  :root {
    --fs-1: 51px;
    --fs-2: 45px;
    --fs-3: 38px;
    --fs-4: 34px;
    --fs-5: 29px;
    --fs-6: 14px;
    --fs-7: 10px;
  }
}

/* Extra large screens */
@media (min-width: 1920px) {
  :root {
    --fs-1: 64px;
    --fs-2: 56px;
    --fs-3: 48px;
    --fs-4: 42px;
    --fs-5: 36px;
    --fs-6: 18px;
    --fs-7: 12px;
  }
}

/* Applying the variables to Bootstrap utility classes */
.fs-1 {
  font-size: var(--fs-1);
}
.fs-2 {
  font-size: var(--fs-2);
}
.fs-3 {
  font-size: var(--fs-3);
}
.fs-4 {
  font-size: var(--fs-4);
}
.fs-5 {
  font-size: var(--fs-5);
}
.fs-6 {
  font-size: var(--fs-6);
}

.fs-7 {
  font-size: var(--fs-7);
}

.tiny-text {
  font-size: xx-small;
  font-weight: bold;
  margin: 8px 0;
}

$blue: #1e90ff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$cricket-green: #004225;
$white: #fff;
$cream: #f5f5dc;
$gray: #6c757d;
$gray-dark: #212529;
$gray-100: #f8f9fa;

$primary: $blue;
$secondary: $gray;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100; // typically a lighter gray, not defined above
$dark: $gray-dark;
