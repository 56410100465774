@import "./Variables.scss";

/** Used to style the draggable cards */
.draggable-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $cricket-green;
  border-radius: 4px;
  min-height: 96px;
  cursor: pointer;
  color: $cream;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;

  &:hover:is(.active) {
    box-shadow: 0 0px 8px rgba(255, 255, 255, 0.8),
      4px 8px 40px rgba(0, 0, 0, 0.2); // Enhanced shadow for hover
    cursor: grab; // Change cursor to indicate draggable
  }

  &.active {
    border: none;
    outline: none;
    z-index: 0;
    border-radius: 4px;
    position: relative;
  }

  &.active::after {
    content: "";
    z-index: -1;
    position: absolute;
    //background-color: $cricket-green;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 4px;
  }

  .duration {
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 5;
    font-size: x-small;
    background-color: $cream;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: $dark;
    font-weight: bold;
    text-align: center;
  }

  .text {
    font-weight: bold;
    font-size: 0.65rem;
    margin: 0.5rem 0;
    text-align: center;
    z-index: 1;
  }

  .subtext {
    font-size: x-small;
    text-align: center;
  }

  &.cooldown-expired {
    animation: scaleAnimation 0.15s ease forwards;
  }
}

.cautious-card {
  &.active::after {
    color: #dcdcdc;
  }
}

.aggressive-card {
  &.active:after {
    color: $dark;
  }
}

.consolidate-card {
  &.active:after {
    color: #e0ffff;
  }
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 2; // Ensure this is below the .card-overlay z-index
}

.card-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(128, 128, 128, 0.8);
  transition: width 0.5s ease;
  z-index: 100; // High z-index to keep this on top
  width: 0%;
}

@keyframes glowing {
  0% {
    background-position: 0, 0;
  }
  50% {
    background-position: 400%, 0;
  }
  100% {
    background-position: 0, 0;
  }
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
