@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import "./Variables.scss";

html,
body,
#root {
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif; // Apply Poppins globally
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  background-color: $dark;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.bg-cream {
  background-color: $cream;
}

.text-cricket-green {
  color: $cricket-green;
}

/** Used to style the recent deliveries*/
.last-30-deliveries {
  display: flex;
  justify-content: start;
  align-items: center;
  overflow-x: none;
  height: 36px;

  .delivery {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 0px solid lightgray;
    padding: 0 0.25rem;
    border-radius: 4px;
    width: 36px;
    margin: 0 0.25rem;
    font-weight: bold;
    font-size: small;
  }
}

/** Table styling */
table tr {
  cursor: default;
}

.transparent-table tr,
.transparent-table th,
.transparent-table td {
  background-color: transparent !important;
  color: #fff !important; /* Ensures text color is white for visibility */
  border: none; /* Removes borders */
  font-size: var(--fs-5); /* Sets the font size based on a CSS variable */
}

/** Used to color the on strike and off strike batsman */
table .off-strike-active td {
  background-color: lightgray;
}

// Used for thea headings of scorecard tables
table .scorecard-table {
  th {
    background-color: $cricket-green;
    color: $light;
    font-size: var(--fs-7);
    font-weight: bold;
    &:not(:first-child) {
      text-align: center;
    }
  }

  td {
    background-color: $cricket-green;
    color: $light;
    font-size: smaller;
    &:first-child {
      font-weight: bold;
    }
    &:not(:first-child) {
      text-align: center;
    }
  }
}

.custom-scorecard-tabs {
  // Base styles for all tabs
  .nav-link {
    background-color: lightgrey; // Light gray background for not selected tabs
    color: $dark; // Dark text for not selected tabs
    border: 0px; // Smooth out the design with a transparent border
    padding: 8px 16px; // Padding for better spacing
    font-size: small;

    &:hover {
      background-color: $cricket-green; // Slightly darker on hover
      color: #fff; // Black text on hover
    }
  }

  // Styles for the active (selected) tab
  .nav-link.active {
    background-color: $cricket-green; // Bootstrap primary color for selected tab
    color: #fff; // White text for selected tab
    text-decoration: underline;
  }
}

table .innings-component-scorecard {
  font-size: small;

  th {
    background-color: transparent;
    color: $light;
    font-weight: bold;
  }

  td {
    background-color: transparent;
    color: $light;
  }
}

.bg-cricket-green {
  background-color: $cricket-green;
  color: $light;
}

.play-match-button {
  width: 100%;
  height: auto;
  font-weight: bold;
  font-size: var(--fs-5);
  padding: 0.5rem 0;
}

.speed-change-button,
.match-options-button,
.change-bowler-button {
  width: 108px;
  height: 72px;
}

.profile-image-container {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  overflow: hidden; /* Ensures no part of the image spills outside the container */
  height: 100%; /* Full height of the column */
}

.profile-image {
  max-width: 100%; /* Image can scale down but not exceed container width */
  max-height: 100%; /* Image can scale down but not exceed container height */
  width: auto;
  height: 100%;
  object-fit: cover;
}

.tiny-card-container {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
}

.tiny-card-inner-container {
  position: relative;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.tiny-card {
  width: 100%;
  height: auto;
}

// .tiny-card img {
//   width: 100%;
// }

.tiny-card-duration {
  position: absolute;
  bottom: 0%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.cautious-mini-card {
  background-color: #003366;
}

.consolidate-mini-card {
  background-color: #4b0082;
}

.aggressive-mini-card {
  background-color: #800000;
}

.custom-checkbox .form-check-input:checked {
  background-color: $primary; /* Green background when checked */
  border-color: $primary; /* Green border for consistency */
}

.custom-checkbox .form-check-input:indeterminate {
  background-color: #ff9800; /* Orange background for indeterminate state */
  border-color: #ff9800; /* Orange border */
}

.next-batsman-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

// Fall of wicket modal
.batsman-container {
  width: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.batsman-display {
  position: absolute;
  border-radius: 0;
  width: 100%;
  text-align: center;
  animation-duration: 0.5s;
}

.rounded {
  border-radius: 4px;
}

.square {
  border-radius: 0px;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.h-10 {
  height: 10%;
}

.h-20 {
  height: 20%;
}

.h-40 {
  height: 40%;
}

.h-80 {
  height: 80%;
}

.minHeight-72 {
  min-height: 72px;
}

.draggable-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  cursor: grab;
}

.home-header {
  height: 72px;
  background-color: $cricket-green;
}
.options-card {
  position: relative;
  width: 100%;
  height: 100%; // Adjust based on your preference
  background-color: #f0f0f0; // Light grey background, adjust as needed
  border-radius: 10px; // Rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  transition: transform 0.15s ease, box-shadow 0.15s ease; // Smooth transition for hover effects
  overflow: hidden; // Ensures no content spills out
  display: flex; // Flexbox for aligning inner content
  align-items: center; // Center align vertically
  justify-content: center; // Center align horizontally
  text-align: center; // Ensures text is centered if multiple lines are used

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $light; // Ensure this variable is defined in your SCSS variables for contrast
    display: flex;
    align-items: center; // Center content vertically
    justify-content: center; // Center content horizontally
    background-color: rgba(
      0,
      0,
      0,
      0.25
    ); // Semi-transparent background for better readability
    font-size: var(
      --fs-500
    ); // Custom property for font size, ensure it's defined

    &:hover {
      color: #007bff; // Changes text color on hover, adjust as needed
    }
  }

  &:hover {
    transform: scale(0.99); // Slightly enlarges the card on hover
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.25); // Darker and larger shadow on hover
  }
}

.light-overlay {
  position: absolute;
  background-color: $dark;
  opacity: 0.75;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.development-message {
  display: none; // Default to hidden for all screen sizes
}

@media (max-width: 1023px), (max-height: 767px) {
  .app-content {
    display: none; // Hide app content on smaller screens
  }

  .development-message {
    display: flex !important; // Force display, ensuring it overrides Bootstrap or other styles
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh; // Ensure it covers the entire viewport height
    background-color: rgba(
      0,
      0,
      0,
      0.75
    ); // Add a background color for visibility

    .card {
      width: auto; // Adjust based on your design needs
      background-color: #333; // Make sure card is visible
    }
  }
}

.no-resize {
  resize: none;
}

.aggression-bar {
  min-width: 120px;
  height: 100%;
}

.small-btn {
  border-radius: 4px;
  padding: 0rem 0.5rem; /* Adjust padding to decrease button height */
  font-size: 0.875rem; /* Optional: Adjust font size if needed */
  line-height: 1.5; /* Adjust line height for vertical alignment */
}
