@import "./Variables.scss";

.game-menu {
  z-index: 2;
}

.menu-item {
  color: $light;
  margin-bottom: 1rem;
  margin-top: 1rem;
  transition: margin-left 0.3s ease;
  cursor: pointer;

  &.active {
    font-weight: bolder;
    color: $primary;
  }

  &:hover {
    margin-left: 1rem;
  }
}

.player-description {
  font-size: var(--fs-400);
}

.player-profile-card {
  padding: 1rem;
}

.profile-img-container {
  width: 100%; /* Fixed width for the image container */
  height: auto; /* Fixed height for the image container */
  overflow: hidden; /* Hide any part of the image that overflows */
  display: flex; /* Ensure the container can use flex properties */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
}

.profile-img-container img {
  width: 100%; /* Image takes full width of the container */
  height: auto; /* Image takes full height of the container */
  object-fit: scale-down; /* Cover the container, clipping if necessary */
}

.player-card {
  cursor: pointer;
  margin: 4px;
  color: $dark;
  overflow: hidden;
  position: relative;
  border: 3px solid $light;
  scale: 0.95;
  transition: all 0.3s ease;
  border-radius: 4px;

  &:not(.disabled):hover {
    box-shadow: 0 0 0.25rem 0.25rem rgba(255, 255, 255, 0.8);
    scale: 1;
  }

  &.disabled {
    cursor: default;
    color: $light;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1; // Lower z-index for the overlay
    }

    .icon-lock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -75%);
      font-size: 30px; // Adjust the size as needed
      color: $white; // Ensure the icon is visible on the dark background
      z-index: 2; // Higher z-index to ensure it's on top of the overlay
    }
  }

  .player-selected {
    position: absolute;
    top: -2px;
    right: 0px;
    padding: 2px;
    background-color: transparent;
  }

  .player-name {
    font-size: var(--fs-7);
  }
}

.selected-player-image-container {
  height: auto; /* Fixed height for the image container */
  overflow: hidden; /* Hide any part of the image that overflows */
  display: flex; /* Ensure the container can use flex properties */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  position: relative; /* Relative positioning context for absolute children */
}

.selected-player-image-container img {
  width: 100%; /* Image takes full width of the container */
  height: 100%; /* Image takes full height of the container */
  object-fit: cover; /* Cover the container, clipping if necessary */
}

.selected-player-overlay {
  position: absolute; /* Position the div absolutely inside the relative container */
  bottom: 0; /* Align it to the bottom */
  left: 0; /* Stretch from the left edge */
  right: 0; /* Stretch to the right edge */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.45) 75%,
    rgba(0, 0, 0, 1) 100%
  ); /* Gradient background from solid to transparent */
  color: white; /* Text color */
  text-align: center; /* Center text horizontally */
  padding: 10px; /* Padding around the text */
  height: 100%;
}

.selected-player-statistics-container {
  overflow-y: auto;
}

.max-height-100 {
  max-height: 100%;
}

.max-height-75 {
  max-height: 75%;
}

.max-height-50 {
  max-height: 50%;
}

.h-65 {
  height: 65%;
}

.h-35 {
  height: 35%;
}

.h-70 {
  height: 70%;
}

.h-30 {
  height: 30%;
}

.match-center-card {
  margin: 0; /* Removes margin around the card */
  padding: 0; /* Removes padding within the card */
  border: none; /* Removes any borders */
  position: relative;
  overflow: hidden;
  min-height: 90px;
  width: 100%;
}

.match-center-overlay {
  position: absolute; /* Positions the overlay relative to the nearest positioned ancestor (the card) */
  top: 0; /* Aligns the top edge of the overlay with the top of the card */
  right: 0; /* Aligns the right edge of the overlay with the right of the card */
  bottom: 0; /* Aligns the bottom edge of the overlay with the bottom of the card */
  left: 0; /* Aligns the left edge of the overlay with the left of the card */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Adds a semi-transparent black overlay over the image */
  color: #fff; /* Optional: Ensures text color is white for better visibility */
}

table .squad-table tr,
th,
td {
  font-size: 0.75rem;
  padding: 0;
}

.table.squad-table tbody {
  height: 300px; /* Dynamic adjustment in your JS */
  overflow-y: scroll; /* Changed from visible to scroll */
}

.player-role-icon {
  height: 100%;

  img {
    max-width: 16px;
    height: auto;
    object-fit: scale-down; /* Cover the container, clipping if necessary */
    border-radius: 4px;
  }
}

.game-header {
  position: relative;

  &.game-header-overlay {
    bottom: 0; /* Align it to the bottom */
    left: 0; /* Stretch from the left edge */
    right: 0; /* Stretch to the right edge */
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.25) 25%,
      rgba(0, 0, 0, 0.25) 50%,
      rgba(0, 0, 0, 0.25) 75%,
      rgba(0, 0, 0, 0.25) 100%
    );
  }
}

.game-settings-icon {
  color: $light;

  :hover {
    color: $light;
    cursor: pointer;
  }
}

.bg-main-image {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 1; /* Make sure this has a higher z-index */
}

.bg-main-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../Images/GeneralImages/stadium_background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(8px);
  z-index: -1; /* Set to -1 to ensure it is below .bg-main-image */
}

.login-image {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 1; /* Make sure this has a higher z-index */
}

.login-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../Images/profileImages/newZealand_3.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; /* Set to -1 to ensure it is below .bg-main-image */
}

.gear-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.rounded-start {
  border-radius: 8px 0 0 8px;
}

.rounded-end {
  border-radius: 0 8px 8px 0;
}

.login-error {
  height: 20px;
}

.forgot-password:hover {
  text-decoration: underline;
  cursor:pointer;
}
