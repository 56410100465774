@import "./Variables.scss";

// Gear colors
$legendary: #b29500;
$elite: #4c0099;
$professional: #006d77;

.legendary {
  background-color: $legendary;
  color: $light;
}

.elite {
  background-color: $elite;
  color: $light;
}

.professional {
  background-color: $professional;
  color: $light;
}

.gear-item-card {
  transition: all 0.3s ease;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); // Initial state with no shadow
  opacity: 1;
  scale: 0.95;
  border: 2px solid;

  &:hover {
    opacity: 1;
    scale: 1;
  }
  &.legendary:hover {
    box-shadow: 0 0px 8px 8px rgba(178, 149, 0, 0.8); // Shadow on hover
  }
  &.elite:hover {
    box-shadow: 0 0px 8px 8px rgba(76, 0, 153, 0.8); // Shadow on hover
  }
  &.professional:hover {
    box-shadow: 0 0px 8px 8px rgba(0, 109, 119, 0.8); // Shadow on hover
  }
}
