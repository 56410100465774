@import "./Variables.scss";

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: $dark;
  color: $light;
  z-index: 10000;
}
